<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-col lg="12" sm="12">
          <v-btn block outlined v-bind="attrs" v-on="on" :disabled="disabled"> Visualizar </v-btn>
        </v-col>
      </template>

      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Material Complementar</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false"> Fechar </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list three-line subheader v-for="aula in filteredAulas" :key="aula.videoId">
          <v-subheader>{{ aula.title }}</v-subheader>
          <v-list-item v-for="file in aula.arquivos" :key="file.title" :link="true" :href="file.url" target="_blank">
            <v-list-item-avatar>
              <v-icon class="grey lighten-1" dark> mdi-clipboard-text </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="file.title" class="text-left"></v-list-item-title>

              <v-list-item-subtitle class="text-left" v-text="file.size + ' Bytes'"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon :link="true" :href="file.url" target="_blank">
                <v-icon color="primary lighten-1">mdi-cloud-download</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-spacer></v-spacer>
          <v-divider></v-divider>
        </v-list>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    curso: Object,
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
    };
  },
  methods: {
    getValues (object) {
      if (object) {
        let keys = Object.keys(object);
        let values = Object.values(object);
        // console.log(values)
        if (keys) {
          keys.forEach(function (key, index) {
            delete values[index].hash;
            if (key) {
              values[index].hash = key;
              values[index].key = key;
            }
          });
        }
        return values;
      }
      return [];
    },
  },
  computed: {
    filteredAulas () {
      let aulas = [];
      let aulasList = this.curso && this.curso.aulas ? this.getValues(this.curso.aulas): [];
      aulasList.forEach((aula) => {
        if (aula.arquivos) {
          aulas.push(aula);
        }
      });
      return aulas;
    },
  },
};
</script>