<template>
  <v-app>
    <v-speed-dial id="create" v-if="!drawer2" v-model="fab" :top="top" :bottom="bottom" :right="right" :left="left"
      :direction="direction" :open-on-hover="hover" :transition="transition" class="hidden-md-and-down">
      <template v-slot:activator>
        <v-btn dark top right color="primary" class="v-btn--conteudo_curso" @click="setDrawer(!drawer2)">
          <v-icon left>mdi-arrow-left</v-icon>

          <span v-if="fab">Conteúdo do Curso</span>
        </v-btn>
      </template>
    </v-speed-dial>
    <!-- youtube iframe with progressive enhancement (extra queries after the url to optimize the embed) -->
    <vue-plyr v-if="showPlayer && videoId" ref="player" crossorigin @max-height="&quot;400px&quot;;">
      <div class="plyr__video-embed videoPlayer" v-if="showPlayer && !isExpirado()" crossorigin ref="playerDiv">
        <iframe v-if="showPlayer" :src="url" allowfullscreen allowtransparency allow="autoplay" ref="playerFrame"
          enablejsapi="1" id="player"></iframe>
      </div>
    </vue-plyr>
    <v-tabs v-model="tabs" :show-arrows="false" id="opcoes">
      <v-tab v-if="!drawer2">Conteúdo do curso</v-tab>
      <v-tab>Visão Geral</v-tab>
      <v-tab>Perguntas e respostas</v-tab>
      <v-tab>Observações</v-tab>
      <v-tab v-if="aula && aula.arquivos">Material Complementar</v-tab>
      <v-tabs-slider></v-tabs-slider>
      <v-tabs-items v-model="tabs">
        <v-tab-item v-if="!drawer2">
          <!-- Conteúdo do Curso -->
          <v-list expand nav>
            <!-- Style cascading bug  -->
            <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
            <div />

            <template v-for="(item, i) in computedItems">
              <base-item-group v-if="item.children" :key="`group-${i}`" :text="false" :item="item">
                <!--  -->
              </base-item-group>

              <base-item v-else :key="`item-${i}`" :item="item" />
            </template>
          </v-list>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <!-- Conteúdo do Curso -->
            <v-card-text>
              <v-row v-if="curso && curso.guia">
                <v-col lg="3" sm="12">
                  <div class="display-1 font-weight-bold">Apostila do Curso</div>
                </v-col>
                <v-col lg="9" sm="12">
                  <v-btn block outlined :link="true" :href="curso.guia" target="guiaCurso" :disabled="expirado">Ver
                    Apostila</v-btn>
                </v-col>
              </v-row>
              <v-row v-if="curso && curso.declaracao">
                <v-col lg="3" sm="12">
                  <div class="display-1 font-weight-bold">Declaração do Curso</div>
                </v-col>
                <v-col lg="9" sm="12">
                  <v-btn block outlined :link="true" :href="urlDeclaracao" target="declaracao" :disabled="isExpirado()">Emitir
                    Declaração</v-btn>
                </v-col>
              </v-row>

              <v-row v-if="curso && curso.conteudoPDF">
                <v-col lg="3" sm="12">
                  <div class="display-1 font-weight-bold">Grade Curricular</div>
                </v-col>
                <v-col lg="9" sm="12">
                  <v-btn block outlined :link="true" :href="curso.conteudoPDF" target="conteudo"
                    :disabled="isExpirado()">Acessar Grade Curricular</v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="3" sm="12">
                  <div class="display-1 font-weight-bold">Material Complementar</div>
                </v-col>
                <v-col lg="9" sm="12">
                  <material-complementar :curso="curso" :disabled="isExpirado()"></material-complementar>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="3" sm="12">
                  <div class="display-1 font-weight-bold">Data da Matrícula:</div>
                </v-col>
                <v-col lg="9" sm="12">
                  <div class="display-1 mb-5">
                    <span v-if="matricula && matricula.dataMatricula">{{ matricula.dataMatricula | formatDate("DD/MM/Y") }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="3" sm="12">
                  <div class="display-1 font-weight-bold">Duração (semanas):</div>
                </v-col>
                <v-col lg="9" sm="12">
                  <div class="display-1 mb-5">
                    <span v-if="curso && curso.permanencia">{{ curso.permanencia }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="3" sm="12">
                  <div class="display-1 font-weight-bold">Data Limite para conclusão:</div>
                </v-col>
                <v-col lg="9" sm="12">
                  <div class="display-1 mb-5">
                    {{ dataLimite | formatDate("DD/MM/Y") }}
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="3" sm="12">
                  <div class="display-1 font-weight-bold">Pelos números</div>
                </v-col>
                <v-col lg="9" sm="12">
                  <div class="display-1 mb-5">
                    Quantidade de Aulas do Curso:
                    {{ quantidadeAulasCurso }} aulas
                  </div>
                  <div class="display-1 mb-5">
                    Quantidade de Aulas concluídas:
                    {{ quantidadeAulasConcluidas }} aulas
                  </div>
                  <div class="display-1 mb-5">
                    Porcentagem do curso concluída:
                    {{ (porcentagemConcluida * 100).toLocaleString() }} %
                  </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col lg="3" sm="12">
                  <div class="display-1 font-weight-bold">Certificado</div>
                </v-col>
                <v-col lg="9" sm="12">
                  <div class="display-1 mb-5">
                    Termine o curso por completo para receber um certificado do
                    curso
                  </div>
                  <v-btn block :disabled="!liberarCertificado" outlined color="primary"
                    @click="buscarCertificado">Certificado</v-btn>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <div v-html="curso.geral" v-if="curso" class="px-5 text-justify"></div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- Perguntas -->
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-list three-line>
                <template v-for="(item, index) in filteredPerguntas">
                  <v-subheader v-if="!item.user" :key="item['.key']" v-text="item.titulo"></v-subheader>

                  <v-divider v-else-if="item.divider" :key="index" :inset="item.inset"></v-divider>

                  <v-list-item v-else :key="item['.key']" @click="editarPergunta(item)" :disabled="isExpirado()">
                    <v-list-item-avatar>
                      <v-img :src="item.user.photoURL" v-if="item.user.photoURL"></v-img>
                      <v-avatar color="primary" v-if="!item.user.photoURL">
                        <v-icon dark> mdi-account-circle </v-icon>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="font-weight-black">{{ item.titulo }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <span class="text--primary">{{
                          item.user.displayName
                        }}</span>
                        <span class="text--primary" v-if="item.user.isAdmin">
                          - Instrutor</span>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        {{ item.detalhes }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
                            <v-badge :content="getQuantidadeRespostas(item)" :value="getQuantidadeRespostas(item)"
                              color="primary" overlap>
                              <v-icon color="primary">
                                mdi-message-outline
                              </v-icon>
                            </v-badge>
                          </v-btn>
                        </template>
                        <span>Responder</span>
                      </v-tooltip>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-divider :key="index"></v-divider>
                </template>
              </v-list>
            </v-card-text>
            <v-card-actions v-if="!isExpirado()">
              <v-col>
                <v-form>
                  <hr class="mb-5" />
                  <v-card-title class="mb-5 pl-0">Faça uma pergunta</v-card-title>
                  <v-text-field v-model="pergunta.titulo" filled label="Título ou resumo"></v-text-field>
                  <v-text-field v-model="pergunta.detalhes" filled label="Detalhes"></v-text-field>
                  <v-btn class="mr-4" @click="addPergunta" block>Enviar</v-btn>
                </v-form>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <!-- Observações -->
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-btn outlined color="primary" block @click="mostrarControlesObservacao"
                :disabled="mostrarObservacao || isExpirado()">
                Criar uma nova anotação
                <v-spacer></v-spacer>
                <v-icon right dark> mdi-plus-circle </v-icon>
              </v-btn>
              <v-badge v-if="mostrarObservacao" :value="hover" color="deep-purple accent-4" :content="currentTimeVideo"
                transition="slide-x-transition"></v-badge>
            </v-card-text>
            <!-- Observações -->
            <template v-for="(obs, index) in filteredObservacoes">
              <v-card flat :key="index">
                <v-card-text>
                  <v-row class="mb-0" align="center">
                    <v-col>
                      <v-badge :value="hover" color="deep-purple accent-4" :content="obs.tempo"
                        transition="slide-x-transition"></v-badge>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon color="error"
                          @click.prevent="excluirObservacao(obs)"><v-icon>mdi-delete</v-icon></v-btn></template>
                      <span>Excluir</span>
                    </v-tooltip>
                  </v-row>
                  <v-row>
                    <v-col>
                      <strong class="title">Anotação</strong>
                      <p class="font-weight-light indigo lighten-5 pa-5" v-html="obs.observacao"></p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>

            <template v-if="mostrarObservacao">
              <v-col cols="12" md="12">
                <!-- <span class="display-1 mb-3">Visão Geral</span> -->
                <ckeditor :editor="editor" v-model="observacao" :config="editorConfig"></ckeditor>
              </v-col>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn outlined @click="mostrarObservacao = false">Cancelar</v-btn>
                <v-btn outlined color="primary" @click.native="salvarObservacao()">Salvar anotação</v-btn>
              </v-card-actions>
            </template>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <v-list subheader two-line>
              <v-list-item v-for="file in filteredArquivos" :key="file.title" :link="true" :href="file.url"
                target="_blank" :disabled="isExpirado()">
                <v-list-item-avatar>
                  <v-icon class="grey lighten-1" dark>
                    mdi-clipboard-text
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-text="file.title" class="text-left"></v-list-item-title>

                  <v-list-item-subtitle class="text-left" v-text="file.size + ' Bytes'"></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon :link="true" :href="file.url" target="_blank">
                    <v-icon color="primary lighten-1">mdi-cloud-download</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <!-- Modal Respostas -->
    <v-dialog v-model="dialogRespostas" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="fecharDialogResposta">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-avatar color="primary" class="mr-5">
            <img v-if="pergunta.user && pergunta.user.photoURL" alt="Avatar" :src="pergunta.user.photoURL" />
            <v-icon dark v-else> mdi-account-circle </v-icon>
          </v-avatar>
          <v-toolbar-title v-if="pergunta.user && pergunta.user.displayName">{{ pergunta.user.displayName }}:
            {{ pergunta.titulo }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items v-if="pergunta.user && pergunta.user.uid == user.uid">
            <!-- excluirPergunta -->
            <v-btn text dark @click.prevent="excluirPergunta(pergunta)"><v-icon left>mdi-delete</v-icon>Excluir</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <p v-text="pergunta.detalhes" class="text-justify"></p>
          <v-divider></v-divider>
          <div v-for="(resposta, index) in filteredRespostas" :key="'resposta_' + index">
            <v-card flat>
              <v-card-text>
                <v-row class="mb-4" align="center">
                  <v-avatar color="grey" class="mr-4">
                    <img v-if="resposta.user && resposta.user.photoURL" alt="Avatar" :src="resposta.user.photoURL" />
                    <v-icon dark v-else> mdi-account-circle </v-icon>
                  </v-avatar>
                  <strong class="subtitle-1">{{
                    resposta.user.displayName
                  }}</strong>
                  <span class="caption" v-if="resposta.user.isAdmin">
                    - Instrutor</span>
                  <v-spacer></v-spacer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon color="error" v-if="resposta.user.uid == user.uid"
                        @click.prevent="
                          excluirResposta(pergunta.hash, resposta.hash)
                          "><v-icon>mdi-delete</v-icon></v-btn></template>
                    <span>Excluir</span>
                  </v-tooltip>
                </v-row>
                <p class="font-weight-light" v-text="resposta.detalhes"></p>
              </v-card-text>
            </v-card>
            <v-divider></v-divider>
          </div>
        </v-card-text>
        <v-card-actions v-if="!isExpirado()">
          <v-text-field v-model="resposta" append-outer-icon="mdi-send" filled label="Resposta" type="text"
            @click:append-outer="addResposta(pergunta)"></v-text-field>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
// Utilities
import { mapState, mapMutations } from "vuex";
import { db } from "@/db";
import toastr from "toastr";
import * as moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-BR");
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// Import translations for the German language.
import "@ckeditor/ckeditor5-build-classic/build/translations/pt-br";
import MaterialComplementar from '@/views/courses/components/core/MaterialComplementar.vue';
// import MatriculaLote from '../../dashboard/components/core/MatriculaLote.vue';

export default {
  components: { MaterialComplementar },
  // components: {MatriculaLote},
  name: "Course",
  firebase () {
    const ref = "/cursos/" + this.$route.params.curso;
    const url =
      "/cursos/" + this.$route.params.curso + "/aulas/" + this.$route.params.id;
    const ref2 = db.ref(url);
    const ementa = "/cursos/" + this.$route.params.curso + "/ementa/";
    const urlPerguntas = "/perguntas/" + this.$route.params.id;
    const urlObservacoes =
      "/observacoes/" + this.$route.params.id + "/" + this.user.uid;
    const aulasUsuarioRef =
      "/users/" +
      this.user.uid +
      "/cursos/" +
      this.$route.params.curso +
      "/aulas/";
    const matriculaRef =
      "/users/" +
      this.user.uid +
      "/cursos/" +
      this.$route.params.curso;
    return {
      curso: db.ref(ref),
      aula: ref2,
      ementa: db.ref(ementa),
      perguntas: db.ref(urlPerguntas),
      observacoes: db.ref(urlObservacoes),
      aulasUsuario: db.ref(aulasUsuarioRef),
      matricula: db.ref(matriculaRef),
    };
  },
  computed: {
    ...mapState(["courseTitle", "showPlayer", "videoId", "user", "avatar"]),
    url () {
      if (this.videoId) {
        return (
          "https://www.youtube.com/embed/" +
          this.videoId +
          "?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1" +
          "&amp;origin=" +
          window.location.href
        );
      }
    },
    urlDeclaracao () {
      return 'https://api.confabular.com.br/api/declaracao/' + this.$route.params.curso + '/' + this.user.uid
    },
    filteredObservacoes () {
      // console.log(this.perguntas)
      return this.observacoes;
    },
    filteredRespostas () {
      // console.log(this.perguntas)
      return this.respostas;
    },
    filteredPerguntas () {
      // console.log(this.perguntas)
      return this.perguntas;
    },
    filteredArquivos () {
      return this.aula && this.aula.arquivos ? this.aula.arquivos : [];
    },
    computedItems () {
      return this.ementa.map(this.mapItem);
    },
    dataLimite(){
      return this.getDataLimite();
    },
    drawer2: {
      get () {
        return this.$store.state.drawer2;
      },
      set (val) {
        this.$store.commit("SET_DRAWER2", val);
      },
    },
    activeFab () {
      switch (this.tabs) {
        case "one":
          return { class: "purple", icon: "account_circle" };
        case "two":
          return { class: "red", icon: "edit" };
        case "three":
          return { class: "green", icon: "keyboard_arrow_up" };
        default:
          return {};
      }
    },
    currentTime () {
      // return this.currentTimeVideo;
      // console.log(this.$refs);
      // return this.$refs.player.player.currentTime;
    },
    quantidadeAulasCurso () {
      if (this.curso) {
        let aulas = this.getValues(this.curso.aulas);
        return aulas.length;
      }
      return 0;
    },
    quantidadeAulasConcluidas () {
      // console.log(this.aulasUsuario)
      let count = 0;
      if (this.aulasUsuario) {
        this.aulasUsuario.forEach((aula) => {
          count = aula.assistido ? count + 1 : count;
        });
      }
      return count;
    },
    porcentagemConcluida () {
      if (this.quantidadeAulasCurso) {
        return this.quantidadeAulasConcluidas / this.quantidadeAulasCurso;
      }
    },
    liberarCertificado () {
      if (this.curso) {
        return (
          this.curso.liberarCertificado &&
          this.porcentagemConcluida * 100 >= this.curso.percentagemConclusao
        );
      }
      return false;
    },
  },

  data: () => ({
    editor: ClassicEditor,
    editorData: "<p>Observações da Aula</p>",
    editorConfig: {
      language: "pt-br",
      //   plugins: [
      //     EssentialsPlugin,
      //     BoldPlugin,
      //     ItalicPlugin,
      //     LinkPlugin,
      //     ParagraphPlugin,
      //   ],

      //   toolbar: {
      //     items: ["bold", "italic", "link", "undo", "redo"],
      //   },
    },
    isDisabled: false,
    aulasUsuario: [],
    dialogRespostas: false,
    pergunta: {},
    resposta: "",
    respostas: [],
    mostrarObservacao: false,
    observacoes: [],
    observacao: "",
    currentTimeVideo: "00:00:00",
    aula: null,
    ementa: [],
    perguntas: [],
    video: "",
    player: {},
    direction: "left",
    fab: false,
    fling: false,
    hover: true,
    tabs: null,
    top: true,
    right: true,
    bottom: false,
    left: false,
    curso: null,
    transition: "slide-x-reverse-transition",
  }),

  methods: {
    ...mapMutations({
      setCourseTitle: "SET_COURSE_TITLE",
      setShowPlayer: "SET_PLAYER_SHOW",
      setVideoId: "SET_VIDEO_ID",
      setDrawer: "SET_DRAWER2",
    }),
    isExpirado () {
      const dataAtual = new Date();
      const novaDataLimite = this.getDataLimite();
      return dataAtual > novaDataLimite;
    },
    getDataLimite () {
      const dataMatricula = new Date(this?.matricula?.dataMatricula);
      const dataMatriculaLimite = new Date(this?.matricula?.dataLimite);
      const dataLimite = new Date(this?.curso?.dataLimite);
      const permanencia = parseInt(this?.curso?.permanencia);

      let novaDataLimite;
      if (!isNaN(dataMatriculaLimite)) {
        novaDataLimite = dataMatriculaLimite;
      } else if (!isNaN(dataMatricula.getTime())) {
        novaDataLimite = new Date(dataMatricula);
        novaDataLimite.setDate(novaDataLimite.getDate() + permanencia * 7);
      } else if (!isNaN(dataLimite)) {
        //console.log('Data Limite: ',dataLimite);
        novaDataLimite = dataLimite;
      } else {
        novaDataLimite = new Date();
      }
      //
      return novaDataLimite;
    },
    buscarCertificado () {
      let url =
        "/certificados/" + this.$route.params.curso + "/" + this.user.uid;
      db.ref(url).on("value", (snapshot) => {
        let certificado = snapshot.val();
        if (certificado) {
          this.$router.push(
            "/certificado/" + this.$route.params.curso + "/" + this.user.uid
          );
        } else {
          this.gerarCertificado();
        }
      });
    },
    gerarCertificado () {
      let url =
        "/certificados/" + this.$route.params.curso + "/" + this.user.uid;

      const tempo = new Date();
      let certificado = {
        finalizado: tempo.getTime(),
        user: this.user,
        curso: this.curso,
      };
      db.ref(url)
        .set(certificado)
        .then(() => {
          let url2 =
            "/users/" +
            this.user.uid +
            "/certificados/" +
            this.$route.params.curso;
          delete certificado.user;
          delete certificado.curso;
          db.ref(url2)
            .set(certificado)
            .then(() => {
              this.$router
                .push(
                  "/certificado/" +
                  this.$route.params.curso +
                  "/" +
                  this.user.uid
                )
                .catch((err) => { });
            })
            .catch((error) => {
              toastr.error(
                "Algo Inesperado aconteceu: " + error.message,
                "Confabular"
              );
            });
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.message,
            "Confabular"
          );
        });
    },
    excluirObservacao (obs) {
      let url =
        "/observacoes/" + this.aula.hash + "/" + this.user.uid + "/" + obs.hash;
      // console.log(obs)
      // console.log(url)
      db.ref(url)
        .remove()
        .then(() => {
          // Update successful.
          // toastr.success("", "Confabular");
          // this.aulas = [];
          this.getObservacoes();
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.message,
            "Confabular"
          );
        });
    },
    salvarObservacao () {
      let url = "/observacoes/" + this.aula.hash + "/" + this.user.uid;
      // console.log(url);
      let observacao = {
        tempo: this.currentTimeVideo,
        user: this.user,
        observacao: this.observacao,
      };
      db.ref(url)
        .push(observacao)
        .then(() => {
          this.mostrarObservacao = false;
          this.observacao = "";
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.message,
            "Confabular"
          );
        });
    },
    mostrarControlesObservacao () {
      this.getCurrentTimePlayer();
      this.mostrarObservacao = true;
    },
    getCurrentTimePlayer () {
      if (this.$refs.player) {
        let currentTime = this.$refs.player.player.currentTime;
        let tempo = new Date(0);
        if (currentTime) {
          tempo.setSeconds(currentTime);
        }
        this.currentTimeVideo = tempo.toISOString().substr(11, 8);
        return this.currentTimeVideo;
      }
      return "00:00:00";
    },
    getObservacoes () {
      let url = "/observacoes/" + this.aula.hash + "/" + this.user.uid;
      // console.log(url);
      db.ref(url).on("value", (snapshot) => {
        let observacoes = snapshot.val();
        if (observacoes) {
          this.observacoes = this.getValues(observacoes);
          // console.log(perguntas)
        } else {
          this.observacoes = [];
        }
      });
    },
    fecharDialogResposta () {
      this.dialogRespostas = false;
      this.pergunta = {};
      this.respostas = [];
    },
    editarResposta (resposta) {
      console.log(resposta);
    },
    excluirResposta (pergunta, resposta) {
      // console.log(pergunta)
      // console.log(resposta)
      let url =
        "/perguntas/" +
        this.aula.hash +
        "/" +
        pergunta +
        "/respostas/" +
        resposta;
      //  console.log(url);
      db.ref(url)
        .remove()
        .then(() => {
          // Update successful.
          // toastr.success("", "Confabular");
          // this.aulas = [];
          this.getRespostas({ hash: pergunta });
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.message,
            "Confabular"
          );
        });
    },
    excluirPergunta (item) {
      // alert(item.title);
      // console.log(item)
      let url = "/perguntas/" + this.aula.hash + "/" + item.hash;
      db.ref(url)
        .remove()
        .then(() => {
          // Update successful.
          // toastr.success("", "Confabular");
          // this.aulas = [];
          // this.getRespostas({ hash: pergunta });
          this.fecharDialogResposta();
        })
        .catch((error) => {
          toastr.error(
            "Algo Inesperado aconteceu: " + error.message,
            "Confabular"
          );
        });
    },
    editarPergunta (item) {
      // alert(item.titulo);
      this.dialogRespostas = true;
      this.pergunta = item;
      this.getRespostas(item);
      // console.log(item);
    },
    getQuantidadeRespostas (item) {
      let qtd = 0;
      if (item.respostas) {
        qtd = Object.keys(item.respostas).length;
      }
      return qtd;
    },
    getRespostas (pergunta) {
      let url =
        "/perguntas/" + this.aula.hash + "/" + pergunta.hash + "/respostas/";
      // console.log(url);
      db.ref(url).on("value", (snapshot) => {
        let respostas = snapshot.val();
        if (respostas) {
          this.respostas = this.getValues(respostas);
          // console.log(respostas);
        } else {
          this.respostas = [];
        }
      });
    },
    addResposta (pergunta) {
      if (this.resposta !== "") {
        let url =
          "/perguntas/" + this.aula.hash + "/" + pergunta.hash + "/respostas/";
        let resposta = {
          detalhes: this.resposta,
          user: this.user,
        };
        this.resposta = "";
        db.ref(url)
          .push(resposta)
          .then(() => { })
          .catch((error) => {
            toastr.error(
              "Algo Inesperado aconteceu: " + error.message,
              "Confabular"
            );
          });
      }
    },
    addPergunta () {
      let url = "/perguntas/" + this.aula.hash;
      if (this.pergunta.titulo && this.pergunta.titulo !== "") {
        let pergunta = {
          titulo: this.pergunta.titulo,
          detalhes: this.pergunta.detalhes || "",
          user: this.user,
        };
        this.pergunta = {};
        db.ref(url)
          .push(pergunta)
          .then(() => { })
          .catch((error) => {
            toastr.error(
              "Algo Inesperado aconteceu: " + error.message,
              "Confabular"
            );
          });
      } else {
      }
    },
    getPerguntas (aula) {
      let url = "/perguntas/" + aula.hash;
      // console.log(url);
      db.ref(url).on("value", (snapshot) => {
        let perguntas = snapshot.val();
        if (perguntas) {
          this.perguntas = this.getValues(perguntas);
          // console.log(perguntas)
        } else {
          this.perguntas = [];
        }
      });
    },
    ultimaAula (item) {
      const url2 =
        "/users/" + this.user.uid + "/cursos/" + this.$route.params.curso;
      db.ref(url2)
        .update({
          ultima: item.hash,
        })
        .then(() => {
          // toastr.success(
          //   "Aula " +
          //     item.title +
          //     " marcada como " +
          //     (item.assistido ? "assistida" : "não assitida") +
          //     "!",
          //   "Confabular"
          // );
        });
    },
    aulaConcluida (item) {
      // console.log("..aulaConcluida...");
      console.log(item);
      const url =
        "/users/" +
        this.user.uid +
        "/cursos/" +
        this.$route.params.curso +
        "/aulas/" +
        item.hash;
      db.ref(url)
        .update({
          assistido: item.assistido,
        })
        .then(() => {
          const url2 =
            "/users/" + this.user.uid + "/cursos/" + this.$route.params.curso;
          db.ref(url2)
            .update({
              ultima: item.hash,
            })
            .then(() => {
              toastr.success(
                "Aula " +
                item.title +
                " marcada como " +
                (item.assistido ? "assistida" : "não assitida") +
                "!",
                "Confabular"
              );
            });
        });
    },
    atualizarVideo (id) {
      if (this.$route.params.id) {
        // console.log(this.$route.params.id);
        this.aula.hash = this.$route.params.id;
      }

      if (id) {
        this.ultimaAula(this.aula);
        this.setShowPlayer(false);
        this.setVideoId(id);
        setTimeout(() => {
          this.setShowPlayer(true);
        }, 1);
      }
    },
    mountVideo (curso, aula) {
      let url = "";
      if (aula) {
        url = "/cursos/" + curso + "/aulas/" + aula;
      } else {
        url = "/cursos/" + curso + "/aulas/";
      }

      let ref = db.ref(url);
      this.$firebaseRefs.aula = ref;

      ref.on("value", (snapshot) => {
        // console.log(url);
        // console.log(snapshot.val());
        let lista = snapshot.val();

        if (lista && !lista.video) {
          lista = this.getValues(snapshot.val());
          // console.log(lista);
          this.aula = lista[0];
          // console.log(this.aula);
        } else {
          this.aula = snapshot.val();
        }

        this.aula.arquivos =
          this.aula && this.aula.arquivos ? this.aula.arquivos : [];
        if (this.aula && this.aula.videoId) {
          // console.log(this.aula);
          // this.aulaConcluida(this.aula);
          this.atualizarVideo(this.aula.videoId);
          this.getPerguntas(this.aula);
          this.getObservacoes();
        }
      });
    },
    mapItem (item) {
      if (item) {
        let dateNow = new Date();
        let dateItem = item.dataPublicacao
          ? new Date(item.dataPublicacao)
          : dateNow;

        if (item.children) {
          // console.log(item)
          item.children = Array.isArray(item.children)
            ? item.children
            : this.getValues(item.children);
        } else {
          item.children = [];
        }

        // console.log(item)
        let data = {
          ...item,
          //children: item.children ? 'item.children.map(this.mapItem)' : undefined,
          children: item.children
            ? item.children.map(this.mapItemChildren)
            : undefined,
          title: this.$t(item.title),
          publicado: dateNow.getTime() >= dateItem.getTime(),
        };
        //  console.log(data)
        return data;
      }
    },
    getValues (object) {
      if (object) {
        let keys = Object.keys(object);
        let values = Object.values(object);
        // console.log(values)
        if (keys) {
          keys.forEach(function (key, index) {
            delete values[index].hash;
            if (key) {
              values[index].hash = key;
              values[index].key = key;
            }
          });
        }
        // console.log(keys)
        // console.log(values)
        // console.log(keys[0])
        return values;
      }
      return [];
    },
    mapItemChildren (item) {
      // console.log(item)
      let url =
        "/users/" +
        this.user.uid +
        "/cursos/" +
        this.$route.params.curso +
        "/aulas/" +
        item.hash;
      // console.log(url)
      let ref = db.ref(url);
      item.assistido = false;

      ref.on("value", (snapshot) => {
        if (snapshot.val()) {
          item.assistido = snapshot.val().assistido || false;
          // console.log(item)
        }
      });

      return {
        ...item,
        title: this.$t(item.title),
        to: "curso/" + this.$route.params.curso + "/aula/" + item.hash,
      };
    },
  },
  created () {
    //this.atualizarVideo(this.$route.params.id)
    // this.setCourseTitle("Confabulando sobre VB-MAPP");
    // console.log(this.aula);
    /*
    AppEvents.forEach((item) => {
      this.$on(item.name, item.callback);
    });
    */

    
    window.aulaConcluida = this.aulaConcluida;
    window.isExpirado = this.isExpirado;
    window.getCurrentTimePlayer = this.getCurrentTimePlayer;
    window.getDataLimite = this.getDataLimite;
    // window.getApp.$emit('APP_AULA_CONCLUIDA')
  },
  updated () {
    // this.setCourseTitle("Confabulando sobre VB-MAPP");
    // console.log(this.curso);
  },
  watch: {
    $route (to, from) {
      // react to route changes...
      const aula = to.params.id;
      const curso = to.params.curso;
      this.mountVideo(curso, aula);
    },
    curso (to, from) {
      this.setCourseTitle(this.curso.titulo);
    },
    $refs (to, from) {
      console.log(to);
      if (to.player) {
        let currentTime = to.player.player.currentTime;
        let tempo = new Date(0);
        if (currentTime) {
          tempo.setSeconds(currentTime);
        }

        this.currentTimeVideo = tempo.toISOString().substr(11, 8);
      }
    },
    // aula (to, from) {
    //   this.atualizarVideo(to.videoId)
    // }
  },
  mounted () {
    const curso = this.$route.params.curso;
    const aula = this.$route.params.id;

    this.mountVideo(curso, aula);
    // console.log(this.$refs.player.player);
    // console.log(this.curso)
    // setTimeout(() => console.log(this.$refs.player.player), 1000);
  },
  filters: {
    formatNumber (value) {
      let number = parseFloat(value);
      return number.toFixed(2);
    },
    formatDate (value, fmt = "D MMM YYYY") {
      return value == null
        ? ""
        : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
  }
};
</script>
<style>
/* This is for documentation purposes and will not be needed in your application */
#create.v-speed-dial {
  position: absolute;
  right: 0;
}

#create.v-speed-dial .v-btn__content span,
#create.v-speed-dial .v-btn__content {
  transition-duration: 5s;
  transition-property: all;
}

#create.v-btn--floating {
  position: relative;
}

#opcoes.v-tabs .v-slide-group__prev {
  display: none;
}

.v-list-group--active.white--text {
  color: #333333 !important;
}

.v-application .primary .v-input--selection-controls__input i,
.v-application .primary .v-list-item__subtitle.text-caption,
.v-application .primary div.v-list-item__title {
  color: #ffffff !important;
}

.v-list-group__items .v-list-item:not(:last-child),
.v-list-group:not(:last-child) {
  border-bottom: 1px solid #dedfe0;
}
</style>
